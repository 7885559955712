@import '../node_modules/bootstrap/scss/functions';

// For additional variables, see
// node_modules/bootstrap/scss/_variables.scss
$gray-200: #f2f2f2;
$gray-300: #d4d4d4;
$gray-400: #b1b1b1;
$gray-500: #535353;
$gray-700: #323232;

$purple: #a96cdd;

$primary: $purple;
$secondary: shade-color($purple, 80%);

// Custom helper classes
// Might be better to create custom
// mixins instead.
.max-width-20 {
  max-width: 20px;
}

// Bootstrap default variables
@import '~bootstrap/scss/bootstrap.scss';

.Pill {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  padding: 10px var(--sizes-size-12, 12px);
  background: #e3e9ee;
  color: #323232;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  min-width: fit-content;

  &__copyIcon {
    border-radius: 8px;
    font-size: 12px;
    line-height: 20px;

    background: #f2f2f2;
    color: var(--colors-grays-black, #000);

    > span {
      cursor: pointer;
    }
  }
}

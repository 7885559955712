@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '/src/global.scss';

.emptyPlaceholderImage {
  width: 400px;
}

.messageFormContainer {
  padding: 30px 150px;

  @media (max-width: 1300px) {
    padding: 25px;
  }
}

.messageInputWrapper {
  height: 40px;

  input {
    outline: 0;
  }
}

.messageSendButton {
  width: 40px;
  height: 40px;
}

.messageBubbleWrapper {
  border-bottom: 1px solid $gray-200;
  padding: 30px 150px;
  transition: all 0.2s;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    padding: 25px;
  }
}

.assistantMessageBackground {
  background-color: $gray-200;
}

.userMessageBackground {
  background-color: $white;
}

.messageSenderImage {
  width: 30px;
}

.messageActionButton {
  img {
    cursor: pointer;
  }
}

.prewrap {
  white-space: pre-wrap;
}

.Summary {
  color: #809fb8;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;

  &__ReadMore {
    color: #735ffa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    text-decoration-line: underline;
    display: contents;
  }
}

.TextLink {
  border-radius: 4px;
  border: 1px solid #ebeaed;
  background: #fff;
  display: flex;
  padding: 8px var(--sizes-size-12, 12px);
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  color: #323232;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 8px;
}
